@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  /* background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      /* box-shadow: var(--boxShadowListingCard); */

      & div:has(> img) {
        /* border-bottom: 0; */
      }
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  /* border-radius: var(--borderRadiusMedium); */
  /* border-top-left-radius: var(--borderRadiusMedium); */
  /* border-top-right-radius: var(--borderRadiusMedium); */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  /* border-radius: 0; */
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;

  position: absolute;
  bottom: 0;
  right: 0;

  position: absolute;
  bottom: 0;
  right: 0;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  height: fit-content;
  line-height: 24px !important;

  background: var(--camoColor);
  color: var(--colorWhite);
  line-height: 2;

  padding: 10px;
  border-radius: 12px 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.categoryTitle {
  /* Font */
  composes: textMedium from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
  margin: 0;
  display: flex;
  gap: 1em;
  align-items: center;

  & div:first-child {
    display: block;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.detailTitle {
  composes: textLarge from global;
  font-size: 60px;
  font-weight: var(--fontWeightBlack);
  font-style: italic;
  color: var(--camoColor);
  margin: 0;
  display: flex;
  gap: 1em;
  align-items: center;
}

.listCategoryTitle {
  composes: categoryTitle;
  text-transform: capitalize;
  color: var(--camoColor) !important;
  margin-bottom: 1em;
}

.tagsContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.tag {
  color: white;
  background-color: var(--huntingOrange);
  text-align: center;
  text-transform: capitalize;
  padding: 0 0.5em;
  font-size: 10px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.categoryListingCardContainer {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 1em;

  position: relative;
}

.draftOverlay {
  background-color: var(--colorWhiteTransparent);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px;

  color: var(--colorGrey700);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  text-align: center;
  gap: 1em;

  z-index: 1;

  & >div:first-child {
    width: 85%;
  }

  & a button {
    padding: 0.5em 1em;
    min-height: unset;
  }
}

.detailListingCardContainer {
  display: flex;
  gap: 1em;

  & > div {
    width: 100%;
  }

  & > div:last-child {
    height: 40vh;
  }
}

.categoryListingCardFeatures {
  display: flex;
  gap: 1em;
  align-items: center;

  & > div {
    width: 100%;
  }

  & > div:last-child {
    height: 12em;

    & img {
      height: 100%;
      object-fit: contain;
    }
  }
}

.detailListingCardFeatures {
  composes: categoryListingCardFeatures;

  & > div {
    width: 100%;
  }

  & > div:first-child {
    height: 100%;

    & img {
      height: 100%;
      object-fit: cover;
    }
  }

  & > div:last-child {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
    column-gap: 0.3em;

    & .detailFeaturesTitle {
      grid-column: span 3 / span 3;
      grid-column-start: 2;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: var(--fontWeightBold);
    }

    & .detailLineFirst {
      grid-row: span 2 / span 2;
      border-right: 2px solid var(--huntingOrange);
      border-bottom: 2px solid var(--huntingOrange);
    }

    & .detailLineSecond {
      grid-row: span 2 / span 2;
      border-right: 2px solid var(--huntingOrange);
    }

    & .detailFeaturesContainer {
      grid-column: span 3 / span 3;
      grid-row: span 4 / span 4;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 0.3em;

      & div {
        text-transform: capitalize;
        color: black;
        font-size: 12px;
      }
    }
  }
}

.categoryListingCardImage {
  width: 100%;
}

div:has( > .searchListingCardImage) {
  display: flex;
  justify-content: center;
}

.searchListingCardImage {
  max-height: 18em;
}

.featuresListContainer:not(:last-child) {
  margin-bottom: 0.5em;
}

.featureTitle {
  text-transform: uppercase;
  color: var(--featureGray);
  font-size: 12px;
  font-weight: normal;
}

.featureDetail {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.categoryListingButtonContainer {
  display: flex;
  gap: 1em;
  justify-content: center;
  margin-top: 1em;

  & button {
    padding: 0 1.5em;
    font-size: 12px;
    min-height: unset;
    height: 30px
  }
}

.categoryWithDelete {
  flex-direction: row;

  @media (--viewportMedium) {
    flex-direction: column;
  }

  @media (--viewportLarge) {
    flex-direction: row;
  }

  & a {
    width: 100%;
  }
}

.detailListingButtonContainer {
  composes: categoryListingButtonContainer;
  justify-content: start;

  & button {
    border-radius: 2px;
  }
}

.listListingButtonContainer {
  composes: categoryListingButtonContainer;
  flex-direction: column;
  margin-top: 0;
}

.sectionListingCardContainer {
  border: 1px solid var(--featureGray);
  border-radius: 4px;

  & div:has(img) {
    text-align: center;
    border-bottom: 1px solid var(--featureGray);
  }

  & img {
    height: 12em;
    object-fit: cover;
  }
}

.searchListingCardContainer {
  /* border: 1px solid var(--featureGray); */
  border: 0;
  /* border-radius: 4px; */
  height: 100%;
  /* box-shadow: 1px 1px 5px 1px #a7a7a7; */

  box-shadow: none;
  background: var(--colorWhite);
  border-radius: 12px;

  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 1px 1px 5px 1px #a7a7a7;
  }

  & div:has(> img) {
    /* border-bottom: 1px solid var(--featureGray); */
    border-bottom: 0;
  }

  & img {
    border: unset !important;
  }
}

.listListingCardContainer {
  border: 1px solid var(--featureGray);
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */

  & > div:first-child {
    width: 20%;
    /* height: calc(100% - 1em); */
    /* position: unset !important; */
    margin: 1em;
    border: 1px solid var(--featureGray);
    border-radius: 10px;

    & img {
      border-radius: 10px;
    }
  }

  & > div:nth-child(2) {
    width: 60%;
  }

  & > div:last-child {
    width: 20%;
  }

  & div:has(> img) {
    /* border-bottom: 1px solid var(--featureGray); */
    /* width: 30%; */
  }

  & img {
    border: unset !important;
  }
}

.mainSectionInfo {
  padding: 0.75em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.categoryBrand {
  color: black;
}

.brand {
  color: var(--featureGray);
  font-weight: var(--fontWeightMedium);
}

.categoryDescription {
  color: var(--featureGray);
  font-size: 12px;
  line-height: 14px;

  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.listDescription {
  composes: categoryDescription;
  color: black !important;
}

.searchDescription {
  composes: categoryDescription;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.authorPicture {
  justify-self: center;

  &:first-of-type {
    justify-self: start;
  }
}

.authorName {
  color: var(--colorGrey900);
  font-weight: var(--fontWeightBold);
  font-weight: var(--fontWeightMedium);
  font-size: 10px;

  & > button {
    color: var(--colorGrey600);
    font-size: 10px;
  }
}

.ratingContainer {
  display: flex;
  align-items: center;
  /* margin-top: 0.5em; */
  gap: 0.5em;

  & span {
    display: flex;
    gap: 0.5em;
  }
}

.ratingStar {
  width: 14px;
  height: 14px;
}

.ratingContainer {
  display: flex;
  gap: 5px;
}

.ratingCounter {
  color: black;
  font-size: 9px;
}

.priceAndButtonsContainer {
  display: flex;
  gap: 1em;
  align-items: center;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1.5em;

  & .priceLabel {
    font-weight: bold;
    color: var(--camoColor);
    font-size: 20px;
  }

  & > div:last-child {
    color: black;
  }
}

.detailDescription {
  margin: 3em 0 0.5em;
  display: flex;
  gap: 1em;

  & > div:first-child {
    width: 25%;
    border-top: 2px solid var(--huntingOrange);
  }

  & > div:last-child {
    width: 100%;
    font-size: 10px;
    line-height: 14px;
    color: black;
  }
}

.landingItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listingCardAuthorContainer {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 5px;
  align-items: center;
}

.authorAndReviewContainer {
  display: none;
  @media (--viewportLarge) {
    display: unset;
    grid-column: span 3 / span 3;
  }
}

.authorAndReviewContainerMobile {
  display: unset;
  margin: 5px 0;
  @media (--viewportLarge) {
    display: none;
  }
}

.isDraft {
  position: relative;
}

.listingMainInfo {
  display: flex;

  & .categoryTitle {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: start;
  }

  & .listingPrice {
    width: 20%;
  }
}

.storeMainInfo {
  flex-direction: column;
}

.categoryLabel {
  color: var(--colorGrey500);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
}

.storeCardContainer {
  cursor: pointer;
}

.storeImage {
  width: 100%;
  height: 126px;
  object-fit: cover;
}

.storeAddress {
  font-size: 10px;
  line-height: 10px;
  color: black;
}

.noImage {
  object-fit: contain;
  background-color: var(--camoColor);
  padding: 4px;
}